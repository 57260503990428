import React from "react";
import Layout from "../../components/Layout";
import { Link } from "react-router-dom";
import "./style.css";
import Fernando from "../../assets/images/landingPage/fernando.png";
import Felipe from "../../assets/images/landingPage/felipe.png";
import Gabriela from "../../assets/images/landingPage/gabriela.png";
import JoaoPaulo from "../../assets/images/landingPage/joao-paulo.png";
import ValeriaMorais from "../../assets/images/landingPage/valeria-morais.png";
import DanieleHolanda from "../../assets/images/landingPage/daniele_holanda.webp";


export default function Faq() {
  return (
    <Layout title="Quem é quem" header className="quemEQuem" balcaoVirtual menuName="institucional">
      <p className="">Conheça a equipe PGM</p>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <img src={Fernando} className="img-fluid" />
        </div>
        <div className="col-md-6 col-sm-12 mt-3 mt-md-0">
          <h5 className="procurador-titulo">Procurador-geral</h5>
          <h4 className="nome-procurador">Fernando Antônio Costa de Oliveira</h4>
          <p>
            Bacharel em Direito e Mestre em Administração e Controladoria pela Universidade Federal do Ceará (UFC),
            com especialização em Ordem Jurídica Constitucional pela UFC. Procurador da Fazenda Nacional - PFN.
            Exerceu os cargos/funções públicas de Procurador-Geral do Estado do Ceará, Procurador-Geral do Município de Fortaleza (CE),
            Secretário-Chefe de Gabinete da Vice Governadoria do Estado do Ceará, Diretor Adjunto Operacional da Assembleia Legislativa do Estado do Ceará,
            Procurador-Chefe da Assembleia Legislativa do Estado do Ceará, Representante Judicial da União no Estado do Ceará,
            Procurador-Chefe substituto da União no Estado do Ceará, Diretor-Presidente da Companhia Cearense de Transportes Metropolitanos (Metrofor),
            Procurador de carreira do Município de Fortaleza, Consultor Técnico Jurídico da Câmara Legislativa do Distrito Federal e Oficial de Justiça do Estado do Ceará.
          </p>
        </div>
      </div>
      <hr className="line-bottom-container" />
      <div className="row mt-3">
        <div className="col-md-4 col-sm-12">
          <img src={Felipe} alt="" />
        </div>
        <div className="col-md-8 col-sm-12 mt-3 mt-md-0">
          <h5 className="procurador-titulo">Subprocurador-Geral</h5>
          <h4 className="nome-procurador">Felipe Augusto Siqueira Costa</h4>
          <p>
            Graduado em Direito, advogado, especialista em Parcerias
            Público-Privadas e Concessões pela Fundação Escola de Sociologia e
            Política de São Paulo - FESPSP e a London School of Economics – LSE,
            com Certificação Internacional CP3P (Certificado de Profissional de
            Parceria Público-Privada). Ocupou, ao longo de sua trajetória
            profissional, os seguintes cargos públicos: assessor de
            desenvolvimento institucional na Procuradoria Geral do Estado do
            Ceará - PGE, procurador-chefe do Departamento de Edificações e
            Rodovias do Estado do Ceará – DER, procurador-chefe do Departamento
            de Arquitetura e Engenharia do Estado do Ceará – DAE, procurador
            administrativo da Procuradoria Geral do Município de Fortaleza - PGM
            e procurador geral-adjunto da Procuradoria Geral do Município de
            Fortaleza - PGM, cargo que ocupa até o momento.
          </p>
        </div>
      </div>
      <hr className="line-bottom-container" />
      <div className="row">
        <div className="col-md-4 col-sm-12">
          <img src={ValeriaMorais} alt="" />
        </div>
        <div className="col-md-8 col-sm-12 mt-3 mt-md-0">
          <h5 className="procurador-titulo">Subprocuradora-Geral</h5>
          <h4 className="nome-procurador">Valéria Moraes Lopes</h4>
          <p>
            Graduada em Direito pela Universidade de Fortaleza, especialista em Direito Processual Civil pela Universidade Federal do Ceará
            em parceria com a Escola Superior da Magistratura do Ceará e mestre em Direito na área de concentração em Relações Privadas e
            Desenvolvimento pelo Centro Universitário 7 de Setembro - UNI7. Procuradora do Município de Fortaleza de carreira desde 2006,
            ocupou, ao longo de sua trajetória profissional, os cargos públicos de Analista Judiciário do Tribunal de Justiça do Estado do Ceará e
            Defensor Público do Estado do Ceará e, atualmente, ocupa o cargo de Procuradora-Geral de Consultoria e Contencioso Tributários.
          </p>
        </div>
      </div>
      <hr className="line-bottom-container" />
      <div className="row">
        <div className="col-md-4 col-sm-12">
          <img src={Gabriela} alt="" />
        </div>
        <div className="col-md-8 col-sm-12 mt-3 mt-md-0">
          <h5 className="procurador-titulo">Subprocuradora-Geral</h5>
          <h4 className="nome-procurador">Gabriela Paulino da Silva</h4>
          <p>
            Graduada em Direito pela Universidade Federal do Ceará (UFC). Especialista em Direito Público Material pelo
            Complexo Jurídico Damásio de Jesus e Mestra em Avaliação de Políticas Públicas pela Universidade Federal
            do Ceará – UFC. É advogada, com expertise nas áreas de Direito Público, Direito Constitucional, Direito
            Administrativo e Direitos Humanos. Atuou na área jurídica e administrativa e na gestão de diversos órgãos da
            Administração Pública Estadual: Tribunal de Contas do Estado do Ceará, Central de Licitações do Estado do Ceará
            (TCE), Procuradoria Geral do Estado do Ceará (PGE), Vice-Governadoria do Estado do Ceará, Superintendência do
            Sistema Estadual de Atendimento Socioeducativo (Seas) e Fundação Regional de Saúde.
          </p>
        </div>
      </div>
      <hr className="line-bottom-container" />
      <div className="row">
        <div className="col-md-4 col-sm-12">
          <img src={JoaoPaulo} alt="" />
        </div>
        <div className="col-md-8 col-sm-12 mt-3 mt-md-0">
          <h5 className="procurador-titulo">Subprocurador-Geral</h5>
          <h4 className="nome-procurador">João Paulo de Sousa Barbosa Nogueira</h4>
          <p>
            Advogado formado pela Universidade de Fortaleza – UNIFOR. Pós graduação em Direito Processual Civil.
            Consultor Jurídico na área de Licitações e Contratos e Direito Empresarial. Procurador do Município de Pacajus.
            Possui mais de 15 anos de experiência nas searas contenciosa e consultiva.
            Atua há mais de 8 (oito) anos na área de licitações e contratos junto à Procuradoria Geral do Município de Fortaleza.
          </p>
        </div>
      </div>
      <hr className="line-bottom-container" />
      <div className="table-responsive">
        <table className="table ">
          <thead>
            <tr>
              <th className="border-left-yellow" scope="col">Setor</th>
              <th className="title-table" scope="col">
                <div className="border-left border-white" style={{width: 100}}>
                  Ramal
                </div>
              </th>
              <th className="title-table" scope="col">
                <div className="border-left border-white">
                  Responsável
                </div>
              </th>
              <th className="title-table" scope="col">
                <div className="border-left border-white">
                  E-mail
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row" className="border-left-yellow">Procuradoria de Desenvolvimento e Pesquisa</th>
              <td className="border-left">3265-7811</td>
              <td className="border-left">Marcelo Sampaio Siqueira</td>
              <td className="border-left">marcelo.siqueira@pgm.fortaleza.ce.gov.br</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">Procuradoria da Fazenda Pública</th>
              <td className="border-left">3265-6524</td>
              <td className="border-left">Arthur Régis Frota Carneiro Araújo</td>
              <td className="border-left">atendimento.da@pgm.fortaleza.ce.gov.br</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">Procuradoria de Processo Administrativo Disciplinar</th>
              <td className="border-left">3265-4881</td>
              <td className="border-left">Eliza Maria Moreira Barbosa</td>
              <td className="border-left">edilene.luca@pgm.fortaleza.ce.gov.br</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">Procuradoria de Urbanismo e Meio Ambiente</th>
              <td className="border-left">3488-2410</td>
              <td className="border-left">Denise Sobreira</td>
              <td className="border-left">pgm.prourma@pgm.fortaleza.ce.gov.br</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">Consultoria Geral</th>
              <td className="border-left">3265-1910</td>
              <td className="border-left">Márcio Augusto de Vasconcelos Diniz</td>
              <td className="border-left">pja@pgm.fortaleza.ce.gov.br</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">Procuradoria Judicial</th>
              <td className="border-left">3265-1856</td>
              <td className="border-left">João Afrânio Montenegro </td>
              <td className="border-left">joaoafranio@secrel.com.br</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">Coordenadoria de Gestão de Tecnologia da Informação</th>
              <td className="border-left">3265-2160</td>
              <td className="border-left">Catulo Hansen</td>
              <td className="border-left">catulo.hansen@pgm.fortaleza.ce.gov.br</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">Coordenadoria Administrativo-Financeira</th>
              <td className="border-left">3265-1803</td>
              <td className="border-left">Caio Moreira</td>
              <td className="border-left">caio.moreira@pgm.fortaleza.ce.gov.br</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">Procuradoria da Administração Indireta - PROCADIN</th>
              <td className="border-left text-center">-</td>
              <td className="border-left">André Diego de Lima Leite</td>
              <td className="border-left">andre.leite@pgm.fortaleza.ce.gov.br</td>
            </tr>
            <tr>
              <th scope="row" className="border-left-yellow">Procuradoria de Licitações, Contratos Administrativos e Controle Externo - PROLIC</th>
              <td className="border-left text-center">-</td>
              <td className="border-left">Daniel Medeiros de Albuquerque</td>
              <td className="border-left">daniel.albuquerque@pgm.fortaleza.ce.gov.br</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Layout>
  );
}
