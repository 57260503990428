import React from 'react';
import logoPgm from "../../assets/images/landingPage/logo-prefeitura-PGM.svg";
import iconTurnOff from "../../assets/images/landingPage/icon-fone.svg";
import './style.scss';
import { isMobileDevice } from '../../helpers/Utils';

type HeaderProps = {
  balcaoId: string;
  onClose: () => void;
  datetime: string;
  rate?: boolean;
};

export function Header(props: HeaderProps) {
  const turnOffCall = () => {
    props.onClose();
  };

  return (
    <div className={`headerContainer ${isMobileDevice() ? 'headerContainerMobile' : ''}`}>
      <div className="headerLeft">
        <img src={logoPgm} alt="Logo da PGM" className="logo" />
        <div className="app">
          <p className="appName">Balcão Virtual</p>
          <p className="appVersion">4.0</p>
        </div>
      </div>
      <div className='headerRight'>
        {props.balcaoId && (
          <div className="containerHeaderTimer">
            {props.datetime.length > 0 && (
              <div className={`headerTimer ${props.rate ? 'finishedCall' : ''}`}>
                <span className="headerTimerTitle">
                  {props.rate ? 'Atendimento encerrado' : 'Tempo de atendimento'}
                </span>
                <span className="headerTimerNumber">{props.datetime}</span>
              </div>
            )}
            <div className="thirdColumnClose" data-title="Encerrar atendimento">
              <div className="iconCall" onClick={turnOffCall}>
                <img src={iconTurnOff} alt="Encerrar chamada" />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
