import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./style.css";
import { Link as LinkScroll } from "react-scroll";
import CarouselNoticia4 from "../../../assets/images/landingPage/atendente_de_verde_circle.webp";
import CarouselNoticia3 from "../../../assets/images/landingPage/consultar_divida_green.webp";
import CarouselNoticia from "../../../assets/images/landingPage/portal-pgm-fortaleza.png";
import CarouselSuporteProtesto from "../../../assets/images/landingPage/suporte.png";
import Atd from "../../../assets/images/landingPage/atendimento-pgm-fortaleza.png";
import ModalBalcaoVirtual from "../../ModalBalcaoVirtual";

export default function CarouselHome() {

  const [showModalBalcaoVirtual, setShowModalBalcaoVirtual] = useState(false);

  const handleShowBalcaoVirtual = async () => {
    setShowModalBalcaoVirtual(true);
  }

  const handleHideBalcaoVirtual = () => {
    setShowModalBalcaoVirtual(false);
  }

  return (
    <>
      <div className="home-content mt-4">
        <div id="carouselExampleIndicators" className="carousel">
          <ol className="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
          </ol>
          <div className="carousel-inner">

            <div className="carousel-item active">
              <div className="col-md-6 col-sm-12">
                <p className="h1 title-home text-white">
                  PGM está de mudança. Veja como ser atendido
                </p>
                <p className="subtitle-home">
                  <strong>Até 19/12:</strong> <br></br>
                  - Atendimento da Dívida Ativa: exclusivamente, de forma online, pelo Balcão Virtual. <br></br>
                  - Protocolo de documentos: exclusivamente via e-mail: protocolo.pgm@pgm.fortaleza.ce.gov.br.
                </p>

                <p className="subtitle-home">
                  <strong>A partir de 20/12,</strong> a PGM volta aos atendimentos presenciais em novo endereço: Rua Israel Bezerra, 570, Dionísio Torres.
                </p>

                <div className="">
                  <LinkScroll smooth={true} duration={500} to="atendimento" onClick={handleShowBalcaoVirtual}>
                    <button type="button" className="carrousel-btn-info carrousel-btn-info-left input-block-level text-center balcao-virtual-button">Acesso ao Balcão Virtual</button>
                  </LinkScroll>
                </div>
              </div>
              <div className="col-md-6 image-home d-none d-md-block d-lg-block">
                <img className="d-block w-100 rounded-circle" src={Atd} alt="First slide" />
              </div>
            </div>

            <div className="carousel-item">
              <div className="col-md-6 col-sm-12">
                <p className="h1 title-home text-white">
                  Bem-vindo(a) ao <br/>Portal da PGM
                </p>
                <p className="subtitle-home">
                  Negocie e quite suas dívidas em poucos minutos. Realize seu atendimento online com um de nossos atendentes no Balcão Virtual.
                  <br/><br/>
                  Clique no botão "Baixar Guia" para obter o passo a passo de como utilizar nossos serviços.
                </p>
                <div className="">
                  <LinkScroll smooth={true} duration={500} to="atendimento">
                    <button type="button" className="carrousel-btn-info carrousel-btn-info-left input-block-level text-center">Negociar agora</button>
                  </LinkScroll>
                  <a href="/Guia_-_Portal_PGM.pdf" style={{ textDecoration: 'none' }}>
                    <button type="button" className="carrousel-btn-info carrousel-btn-info-right input-block-level">Baixe o Guia</button>
                  </a>
                </div>
              </div>
              <div className="col-md-6 image-home d-none d-md-block d-lg-block">
                <img className="d-block w-100 rounded-circle" src={CarouselNoticia} alt="First slide" />
              </div>
            </div>

            <div className="carousel-item">
              <div className="col-md-6 col-sm-12">
                <p className="h1 title-home-v2 text-white">
                  Você pode ser atendido <br/> sem sair de casa<br/>
                </p>
                <p className="subtitle-home">
                  O <strong>atendimento da Dívida Ativa</strong>, passará
                  a ser, preferencialmente, de forma online,
                  via <strong>Balcão Virtual</strong>, a partir de 1°/12/2022.
                </p>
                <p className="subtitle-home">
                  O <strong>atendimento presencial</strong> ocorrerá
                  mediante o agendamento prévio. Para
                  mais detalhes, acesse "Saiba mais".
                </p>
                <LinkScroll smooth={true} duration={500} to="atendimento" style={{ textDecoration: 'none' }} title="Balcão Virtual" onClick={handleShowBalcaoVirtual}>
                  <button type="button" className="carrousel-btn-info carrousel-btn-info-left input-block-level">Balcão Virtual</button>
                </LinkScroll>
                <Link to="/atendimento_sem_sair_de_casa" style={{ textDecoration: 'none' }} title="Saiba mais">
                  <button type="button" className="carrousel-btn-info carrousel-btn-info-right input-block-level px-1">Saiba mais</button>
                </Link>
              </div>
              <div className="col-md-6 image-home d-none d-md-block d-lg-block">
                <img className="d-block w-100 rounded-circle" src={CarouselNoticia4} alt="First slide" />
              </div>
            </div>

            <div className="carousel-item">
              <div className="col-md-6 col-sm-12">
                <p className="h1 title-home-v2 text-white">
                  Suporte do Protesto
                </p>
                <p className="subtitle-home">
                  O Instituto de Protesto do Ceará está disponibilizando o
                   número de <strong>atendimento exclusivo aos devedores das
                  procuradorias: (85) 98200-9501,</strong> para você que precisa
                  regularizar a sua situação financeira.
                </p>
                <p className="subtitle-home">
                  Entre em contato e saiba mais!
                </p>
                <a href="https://api.whatsapp.com/send/?phone=05585982009501&text=Ol%C3%A1+eu+desejo+suporte+sobre+Protesto&type=phone_number&app_absent=0" target='_blank' style={{ textDecoration: 'none' }} title="Contato Suporte">
                  <button type="button" className="carrousel-btn-info carrousel-btn-info-left input-block-level">Contato Suporte</button>
                </a>
              </div>
              <div className="col-md-6 image-home d-none d-md-block d-lg-block">
                <img className="d-block w-100 h-100 rounded-circle" src={CarouselSuporteProtesto} alt="First slide" />
              </div>
            </div>

            <div className="carousel-item">
              <div className="col-md-6 col-sm-12">
                <p className="h1 title-home text-white font-size-45">
                  Consulte suas<br/> Dívidas de Fortaleza<br/>
                </p>
                <p className="subtitle-home">
                  Consulte suas dívidas, gere boleto à vista
                  ou parcelado e acompanhe o andamento de
                  maneira rápida e prática, sem sair de casa.
                </p>
                <Link to="/consultar/debitos/em_aberto/" style={{ textDecoration: 'none' }} title="Consultar Dívidas">
                  <button type="button" className="carrousel-btn-info carrousel-btn-info-left input-block-level">Consultar Dívidas</button>
                </Link>
                <Link to="/duvidas" style={{ textDecoration: 'none' }} title="Consultar Dívidas">
                  <button type="button" className="carrousel-btn-info carrousel-btn-info-right input-block-level px-1">Dúvidas Frequentes</button>
                </Link>
              </div>
              <div className="col-md-6 image-home d-none d-md-block d-lg-block img-circle">
                <img className="d-block w-100" src={CarouselNoticia3} alt="First slide" />
              </div>
            </div>
          </div>
        </div>
        <div className="previous">
          <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
        </div>
        <div className="next">
          <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
      <ModalBalcaoVirtual show={showModalBalcaoVirtual} onHide={handleHideBalcaoVirtual} />
    </>
  )
}
