import React, { useState, useRef, useEffect } from "react";
import { FormHandles, SubmitHandler } from "@unform/core";
import { Form } from "@unform/web";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import BalcaoVirtualService from "../../services/BalcaoVirtualService";
import Button from "../../components/Form/Button";
import Select, { Option } from '../../components/Form/SelectReact';
import Layout from "../../components/Layout";
import { getUid, getUser } from "../../services/AuthService"

import "moment/locale/pt-br";
import "react-day-picker/lib/style.css";
import "./style.css";
import Alert from "../../components/Alert";
import { isChromeOrSafari, randomToken } from "../../helpers/Utils";
import { jitsiToken } from "../../config/constants";
import Show from "../../components/Show";

interface FormData {
  balcao: string;
  status: string;
}

export default function AreaAtendente() {
  const formRef = useRef<FormHandles>(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [statusSelecionado, setStatusSelecionado] = useState("");
  const [idBalcaoSelecionado, setIdBalcaoSelecionado] = useState(0);
  const [renderIcon, setRenderIcon] = useState(false)
  const [balcoes, setBalcoes] = useState<Option[]>([]);

  function getBalcaoSelected(option: any) {
    if (option.attendant_id) {
      const user = JSON.parse(getUser() || "");
      if (option.attendant_id == user.id) {
        setIdBalcaoSelecionado(option.id)
        setStatusSelecionado(option.status)
        setDisabled(true);
        setRenderIcon(true)
      }
    }
  }

  useEffect(() => {
    async function loadBalcoes() {
      let balcoesTemp: Option[] = [];
      await BalcaoVirtualService.balcoes()
        .then((response) => {
          response.forEach((option) => {
            const optionTemp = {
              value: option.id,
              label: `${option.name} ${option.status !== "unavailable" ? '(Em uso)' : ''}`,
              status: option.status,
              meet_link: option.meet_link,
              disabled: option.status !== "unavailable" ? true : false
            }
            balcoesTemp.push(optionTemp);
            getBalcaoSelected(option)
          })
        });
      setBalcoes(balcoesTemp);
    }

    function currentPageActive() {
      const link = document.getElementById("login-link");
      link?.setAttribute("class", "active-link");
    }

    loadBalcoes();
    currentPageActive();
  }, []);

  const createJitsiUrl = () => {
    const token = randomToken();
    const url = (`https://jitsi.sefin.fortaleza.ce.gov.br/${token}`);
    return {url, token};
  };

  const handleSubmit: SubmitHandler<FormData> = async (formData) => {
    try {
      if (idBalcaoSelecionado !== 0) {
        const statusSelecionado = formData.status;
        setDisabled(true);
        setRenderIcon(true);
        const {url, token} = createJitsiUrl();
        setLoading(true);
        formRef.current?.setErrors({});
        setDisabled(true);
        setRenderIcon(true);
        BalcaoVirtualService.changeStatus('available', idBalcaoSelecionado, url)
          .then(() => {
            localStorage.removeItem('document');
            window.location.href =  `video_call/${idBalcaoSelecionado}/${token}`;
            setLoading(false);
        });
      } else {
        setError("Selecione um Balcão")
      }

    } catch (err) {
      setError(err)
      setLoading(false);
    }
  };

  const changeStatus = async (statusSelecionado: string) => {
    if (idBalcaoSelecionado !== 0) {
      setStatusSelecionado(statusSelecionado);
      sessionStorage.setItem("status", statusSelecionado);
      if (statusSelecionado == 'available') {
        const {url, token} = createJitsiUrl();
        BalcaoVirtualService.changeStatus(statusSelecionado, idBalcaoSelecionado, url).
          then(() => {
            localStorage.removeItem('document');
            window.open(`video_call/${idBalcaoSelecionado}/${token}`, "_blank")
          });
      } else {
        BalcaoVirtualService.changeStatus(statusSelecionado, idBalcaoSelecionado, '');
      }
    }
  }

  return (
    <Layout title="Olá, atendente!" className="full-container" menuInternal balcaoSelecionado={idBalcaoSelecionado}>
      <Show if={isChromeOrSafari()}>
        <p className="text-info-atendente">Escolha um balcão para abrir chamada do Google Meet e iniciar atendimentos.</p>
        <Form ref={formRef} onSubmit={handleSubmit} className="form-area-atendente">
          {error && (<Alert>{error}</Alert>)}
          <div className="row mt-3">
            <div className="col-12 col-sm-6 col-lg-5">
              <div className="form-group">
                <Select
                  disabled={disabled}
                  label="Qual seu balcão?"
                  name="balcao"
                  optionValue={Number(idBalcaoSelecionado)}
                  placeholder="Selecione um Balcão"
                  options={balcoes}
                  onchange={e => setIdBalcaoSelecionado(e)}
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-5 mt-auto">
              <div className="row group-balcao-check">
                <Button className="btn btn-primary btn-select-balcao mr-2" label="Utilizar Balcão" loading={loading} />
                {renderIcon && <FontAwesomeIcon className="mt-2 balcao-check" icon={faCheckCircle} />}
              </div>
            </div>
          </div>
        </Form>
      </Show>
    </Layout>
  );
}
