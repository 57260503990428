import { get, postCustomize } from "../config/api";
import { ServerData } from "../helpers/Service";
import { isMobileDevice } from "../helpers/Utils";
import { getToken, getClient, getUid, TOKEN_KEY } from "./AuthService"

export interface Balcao {
  on_call: boolean;
  on_attendant?: boolean;
  show_data: boolean;
  id: number;
  name: string;
  attendant: any;
  meet_link: string;
  jwt_token: string;
  status: string;
  attendant_id: number;
  attendant_name: string;
  contributor_document: string | string[];
  updated_at: Date;
  attendance_id: number;
  is_mobile?: boolean;
  documents: string[];
  attendance_name: string;
}

export default class BalcaoVirtualService {

  public static async balcoes() {
    return get<Balcao[]>("/balcoes/listar")
      .then((response) => response);
  }

  public static async balcoesPorStatus(status: string) {
    return get<Balcao[]>("/balcoes/listar/", { status: status })
      .then((response) => response);
  }

  public static async balcaoPorID(id: string) {
    return get<Balcao>(`/balcoes/${id}`)
      .then((response) => response);
  }

  public static async getServiceName(id: string) {
    return get<Balcao>(`/balcoes/${id}/get_service_name`)
      .then((response) => response);
  }

  public static async changeStatus(status: string, id: number, meet: string, documents?: string[], contributorName?: string, name?: string, reason?: string) {
    const token = getToken(), client = getClient(), uid = getUid();
    return postCustomize<ServerData<Balcao>>(`/balcoes/${id}/change_status`, {
      status,
      meet,
      name,
      is_mobile: isMobileDevice(),
      contributor_document: documents && documents,
      contributor_name: contributorName,
      reason: reason
    },
      { headers: { 'Access-Token': token, 'client': client, 'Uid': uid } }
    ).then((response) => response)
  }

  public static async setOnCall(id: number) {
    const token = getToken(), client = getClient(), uid = getUid();
    return postCustomize<ServerData<Balcao>>(`/balcoes/${id}/set_on_call`,
      { headers: { 'Access-Token': token, 'client': client, 'Uid': uid } }
    ).then((response) => response)
  }

  public static async setOnAttendant(id: number) {
    const token = getToken(), client = getClient(), uid = getUid();
    return postCustomize<ServerData<Balcao>>(`/balcoes/${id}/set_on_attendant`,
      { headers: { 'Access-Token': token, 'client': client, 'Uid': uid } }
    ).then((response) => response)
  }

  public static async setShowData(id: number) {
    const token = getToken(), client = getClient(), uid = getUid();
    return postCustomize<ServerData<Balcao>>(`/balcoes/${id}/set_show_data`,
      { headers: { 'Access-Token': token, 'client': client, 'Uid': uid } }
    ).then((response) => response)
  }

  public static async updateAttendanceLastSeen(id: number) {
    const token = getToken(), client = getClient(), uid = getUid();
    return postCustomize<ServerData<Balcao>>(`/balcoes/${id}/update_attendance_last_seen`,
      { headers: { 'Access-Token': token, 'client': client, 'Uid': uid } }
    ).then((response) => response)
  }

  public static async updateContributorLastSeen(id: number) {
    const token = getToken(), client = getClient(), uid = getUid();
    return postCustomize<ServerData<Balcao>>(`/balcoes/${id}/update_contributor_last_seen`,
      { headers: { 'Access-Token': token, 'client': client, 'Uid': uid } }
    ).then((response) => response)
  }
}
