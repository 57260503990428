import React from 'react';
import './style.scss';
import Show from '../../../components/Show';
import { isMobileDevice } from '../../../helpers/Utils';

const refreshImg = require('../images/refresh.svg');
const eyeImg = require('../images/eye.svg');
const warningImg = require('../images/warning.svg');
const checkImg = require('../images/check.svg');

export function ContributorIntructions() {
  return (
    <div className='attendantInstructionsContainer'>
      <div className='attendantInstructionsItem'>
        <div  className='attendantInstructionsImg'>
          <img src={checkImg} />
        </div>
        <p className='attendantInstructionsText'>Tenha em mão um documento oficial com foto para comprovar sua identidade.</p>
      </div>
      <Show if={!isMobileDevice()}>
        <div className='attendantInstructionsItem'>
          <div  className='attendantInstructionsImg'>
            <img src={eyeImg} />
          </div>
          <p className='attendantInstructionsText'>Você poderá ver todas suas dívidas no painel ao lado e poderá consultar e gerar novas negociações com a ajuda do atendente.</p>
        </div>

        <div className='attendantInstructionsItem'>
          <div  className='attendantInstructionsImg'>
            <img src={refreshImg} />
          </div>
          <p className='attendantInstructionsText'>Ao clicar no ícone verde na lateral da direita da sua tela, você atualizará suas negociações. Faça isso apenas quando o atendente solicitar.</p>
        </div>
      </Show>
      <div className='attendantInstructionsItem'>
        <p className='attendantEmail'>Nosso e-mail: atendimento.da@pgm.fortaleza.ce.gov.br</p>
      </div>
    </div>
  );
}
