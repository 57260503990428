import React, { useEffect, useState, useRef } from 'react';
import './style.scss';
import Lottie from "lottie-react";
import BalcaoVirtualService from '../../../services/BalcaoVirtualService';
import ConsultarService from '../../../services/ConsultarService';
import { callType } from '..';

const waitingLottie = require('../../../assets/lotties/waiting.json');

type LoadingProps = {
  balcaoId: string;
  onAccept: (document: string) => void;
  playRing: () => void;
  type: callType;
  setOnCall: () => void;
  setName: (name: string) => void;
};

export function Loading(props: LoadingProps) {
  const [contributor, setContributor] = useState<string | undefined>();
  const [document, setDocument] = useState<string>('');
  const isRunning = useRef(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    startPolling();
    return () => stopPolling();
  }, []);

  useEffect(() => {
    if (contributor && props.type !== 'contributor') {
      props.playRing();
    }
  }, [contributor]);

  const startPolling = () => {
    if (intervalRef.current) return;

    intervalRef.current = setInterval(() => {
      if (!isRunning.current) {
        loadBalcao();
      }
    }, 3000);
  };

  const stopPolling = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const loadBalcao = async () => {
    isRunning.current = true;
    try {
      const res = await BalcaoVirtualService.balcaoPorID(props.balcaoId);

      if (props.type === 'attendant') {
        if (res.contributor_document === null) {
          return;
        } else {
          const document = JSON.parse(res.contributor_document as string);
          const contributorResponse = await ConsultarService.contributor({
            contributor_document: document[0],
          });
          setContributor(contributorResponse.data.name);
          setDocument(contributorResponse.data.cpf || contributorResponse.data.cnpj);
        }
      } else {
        if (res.on_call && res.on_attendant) {
          props.setOnCall();
        }
      }

      if (res.contributor_document) {
        const document = JSON.parse(res.contributor_document as string);
        setDocument(document[0]);
        const contributorResponse = await ConsultarService.contributor({ contributor_document: document[0] });
        setDocument(contributorResponse.data.cpf || contributorResponse.data.cnpj);

        const serviceNameResponse = await BalcaoVirtualService.getServiceName(props.balcaoId);
        setContributor(serviceNameResponse.attendance_name);
        props.setName(serviceNameResponse.attendance_name);
      }
    } finally {
      isRunning.current = false;
    }
  };

  const renderCall = () => {
    props.onAccept(document);
  };

  const renderWaiting = () => {
    if (props.type === 'contributor') {
      return renderWaitingToContributor();
    }
    return (
      <div className="loadingContainer">
        <Lottie
          animationData={waitingLottie}
          autoplay={true}
          loop={true}
          className="lottie"
        />
        <span className="loadingText">
          Por favor, aguarde. A qualquer momento um contribuinte poderá solicitar atendimento.
        </span>
      </div>
    );
  };

  const renderWaitingToContributor = () => {
    return (
      <div className="loadingContainer">
        <Lottie
          animationData={waitingLottie}
          autoplay={true}
          loop={true}
          className="lottie"
        />
        <span className="loadingText">
          Por favor, aguarde. A qualquer momento um de nossos colaboradores irá te atender.
        </span>

        <span className="loadingTextWarning">
          Na próxima tela, libere o microfone e a câmera.
        </span>
      </div>
    );
  };

  return (
    <>
      {contributor && props.type === 'attendant' ? renderCall() : renderWaiting()}
    </>
  );
}
