import { useLocation } from "react-router-dom";

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const randomToken = (length?: number) => {
  let result = '';
  const tokenSize = length ? length : 25;
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < tokenSize) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return `balcao-virtual-4-0${result}`;
};

export function isChromeOrSafari(): boolean {
  const userAgent = navigator.userAgent;
  const isSafari = /Safari/i.test(userAgent) && !/Chrome/i.test(userAgent);
  const isChrome = /Chrome/i.test(userAgent);

  return isChrome || isSafari;
};

export function isMobileDevice(): boolean {
  const userAgent = navigator.userAgent;
  if (/Android/i.test(userAgent) || /iPhone/i.test(userAgent)) {
    return true;
  }

  return false;
};
