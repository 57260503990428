import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faTrashAlt, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import ConsultarService from "../../services/ConsultarService";
import { isServerError } from "../../config/api";
import "./styles.css";
import { cpfOrCnpjMask } from "../../helpers/Masks";
import BalcaoVirtualService from "../../services/BalcaoVirtualService";
import { useHistory } from "react-router-dom";
import { isMobileDevice } from "../../helpers/Utils";

interface Props {
  onHide?(): void;
  desklink: string;
  attendant: string;
  balcaoId?: number;
  stopReload: () => void;
}

export default function ConsultModal({ onHide, desklink, attendant, balcaoId, stopReload }: React.PropsWithChildren<Props>) {
  const [name, setName] = useState("")
  const [documentNumber, setDocumentNumber] = useState("");
  const [loading, setLoading] = useState(false)
  const [documents, setDocuments] = useState<string[]>(['']);
  const [buttonClass, setButtonClass] = useState('disableButtonConsultModal');
  const [formValid, setFormValid] = useState(false);

  $('#modalBalcao').on('hide.bs.modal', () => {
    if (onHide) onHide();
  });

  useEffect(() => {
    stopReload()
  }, [])

  useEffect(() => {
    checkFormValid();
  }, [documents, name])


  useEffect(() => {
    const newButtonClass = formValid ? "enableButtonConsultModal" : "disableButtonConsultModal"
    setButtonClass(newButtonClass);
  }, [formValid])

  const showChangeElements = (elementId: string) => {
    const deletElement = document.getElementById(`documentInput${elementId}`)?.children[1]
    if(documents[parseInt(elementId)].length >= 14 && documents[parseInt(elementId)].length <= 18) {
      deletElement?.classList.add('deletFieldContainer')
    } else {
      deletElement?.classList.remove('deletFieldContainer')
    }
    if(documents[parseInt(elementId)].length > 0) {
      document.getElementById(`documentInput${elementId}`)?.children[0].classList.add('activeINput')
    } else {
      document.getElementById(`documentInput${elementId}`)?.children[0].classList.remove('activeINput')
    }
  }

  const documentMask = (documentNumber : string, elementId: string) => {
    let cpfCnpjMask = cpfOrCnpjMask(documentNumber).mask()
    let element = document.getElementById(elementId) as HTMLInputElement
    element.value = cpfCnpjMask
    let arrayIndex = (parseInt(elementId))
    documents[arrayIndex] = cpfCnpjMask
    setDocumentNumber(documents[0]);
    checkFormValid();
    showChangeElements(elementId);
  }

  const inputName = (inputName: string, inputId: string) => {
    setName(inputName);
    const inputNameElement = (document.getElementById(inputId) as HTMLInputElement)
    if(inputNameElement.value.length >= 3) {
      inputNameElement.classList.add('activeINput')
    } else {
      inputNameElement.classList.remove('activeINput')
    }
  }

  let isButtonEnabled = false

  const contributorData = {contributor_document: documentNumber.replace(/\D/g,'')}
  const contributor_attendance_histories = {'attendance_place': 'Balcão Virtual',
                                            'attendant': attendant,
                                            'attendance_subject': 'attendment_at_the_counter'};
  const contributor_document = documentNumber.replace(/\D/g,'')
  const attendanceData = {contributor_document, contributor_attendance_histories}

  const registerattendance = async () => {
    await ConsultarService
      .attendanceRegistration(attendanceData)
  }

  const handleSubmit = async () => {
    if(isButtonEnabled){
      const errorSpanMessage = (document.getElementById('errormessageid') as HTMLAnchorElement);
      errorSpanMessage.setAttribute('hidden', 'true')
      const disconectMessage = (document.getElementById('servermessageid') as HTMLAnchorElement);
      disconectMessage.setAttribute('hidden', 'true')

      setLoading(true);
      await ConsultarService
        .contributorStatus(contributorData)
        .then((response) => {
            registerattendance();
            if (balcaoId) {
              ConsultarService
              .contributor({contributor_document: contributorData.contributor_document}).then((rContributor) =>{
                BalcaoVirtualService.changeStatus("busy", balcaoId, '', documents.map((doc) => (doc.replace(/\D/g,''))), response.contributor_name, name)
                .then((r) => {
                  setLoading(false);
                  if (isMobileDevice()) {
                    localStorage.setItem('document', contributorData.contributor_document);
                    window.location.href = `video_call/${balcaoId}/${desklink.split('/').pop()}`;
                  } else {
                    localStorage.setItem('attendance_id', `${r.data.attendance_id}`);
                    localStorage.setItem('document', contributorData.contributor_document);
                    localStorage.setItem('documents', JSON.stringify(documents.map((doc) => (doc.replace(/\D/g,'')))));
                    window.location.href = `video_call/${balcaoId}/${desklink.split('/').pop()}`;
                  }
                })
                .catch(() => {
                  setLoading(false);
                  alert('O balcão selecionado foi ocupado por outro contribuinte');
                  window.location.href = '/'
                })
              })
            }
        }).catch((err) => {
          setLoading(false)
          if (isServerError(err)) {
            const errorSpanMessage = (document.getElementById('errormessageid') as HTMLAnchorElement);
            errorSpanMessage.removeAttribute('hidden')
          } else {
            const disconectMessage = (document.getElementById('servermessageid') as HTMLAnchorElement);
            disconectMessage.removeAttribute('hidden')
          }
        })
    }
  }

  const checkFormValid = () => {
    setFormValid(documents.some(
      (doc) => doc.length >= 14 && doc.length <= 18 && doc.length !== undefined && name.length >= 3
    ));
  }

  const enableButton = () => {
    isButtonEnabled = formValid ? true : false;

    return (
      <div>
        {loading
          ? <button disabled type="submit" className={'disableButtonConsultModal'} onClick={handleSubmit}>
              <strong>
                <FontAwesomeIcon icon={faCircleNotch} spin className="fa-spin-fast" />
              </strong>
            </button>
          : <button type="submit" id='submitButton' className={buttonClass} onClick={handleSubmit}>Continuar</button>
        }
      </div>
    )
  }

  const addInputs = () => {
    setDocuments([...documents, '']);
  };

  const deletFields = (elementId: number) => {
    let element = document.getElementById(elementId.toString()) as HTMLInputElement
    element.value = '';
    document.getElementById(`documentInput${elementId}`)?.children[0].classList.remove('activeINput')
    const newArrayDocuments = documents
    if(elementId !== 0 && elementId > -1) {
      document.getElementById(`documentInput${elementId}`)?.classList.add('documentInputHidden');
      newArrayDocuments[elementId] = ''
      setDocuments(newArrayDocuments);
    } else {
      isButtonEnabled = false
      setFormValid(false);
      setButtonClass("disableButtonConsultModal");
      document.querySelector('[data-title="Excluir"]')?.classList.remove('deletFieldContainer');
    }
  }

  const renderInputs = () => {
    return (
      documents.map((eachOne, i) => {
        return (
          <div key={i} id={`documentInput${(i).toString()}`} className="documentInput">
            <input
              type="text"
              id={(i).toString()}
              placeholder="000.000.000-00"
              onChange={(e) => {documentMask(e.target.value, e.target.id)}}
            />
            <div className="deletFieldContainerHide" data-title="Excluir">
              <FontAwesomeIcon
                id={(i).toString()}
                className="deletFieldIcon"
                icon={faTrashAlt}
                onClick={(e) => {deletFields(parseInt(e.currentTarget.id))}}
              />
            </div>
          </div>
        )}
      )
    )
  }

  return (
    <div id={"updatecontributormodal"} tabIndex={-1} className="contributorModal modal-content">
      <div className="closebuttonmodal">
        <button type="button" className="close btn-close-modal" data-dismiss="modal" aria-label="Close" onClick={onHide}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
        <div>
          <div hidden={true} className="serverinfo" id="errormessageid">
            <span>Não foram encontrados débitos para o CPF/CNPJ informado.</span>
          </div>
          <div hidden={true} className="serverinfo" id="servermessageid">
            <span>Não foi possível estabelecer uma conexão com o servidor. Tente novamente mais tarde.</span>
          </div>
          <div className="modalcontent">
            <FontAwesomeIcon className="userIcon" icon={faUserCircle} />
            <div className="initalMessage">
              <span>Para acessar o Balcão Virtual, por favor preencha abaixo com</span>
              <span>seu nome e o(s) CPF/CNPJ que deseja atendimento.</span>
            </div>
            <form className="modalContentForm" autoComplete="off">
              <span>Seu nome *</span>
              <input type="text" id='nameInput' placeholder="Digite aqui..." onChange={(e) => {inputName(e.target.value, e.currentTarget.id)}} value={name} ></input>
              <span>CPF/CNPJ para atendimento *</span>
              {renderInputs()}
            </form>
            <button className="addField" onClick={addInputs}>Adicionar mais</button>
          </div>
          <div className="modalbottoncontent">
            {enableButton()}
          </div>
        </div>
    </div>
  )
}
